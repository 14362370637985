import React, { useState } from 'react';


function App() {
  const [visibleSection, setVisibleSection] = useState(null);


  const handleScroll = (sectionId) => {
    setVisibleSection(sectionId); // Cambiamos la sección visible dinámicamente
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }}
  

  return (
    <div>
     
      {/* Menú de navegación */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <a className="navbar-brand" href="#inicio">GERPA</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="#inicio">INICIO</a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => handleScroll('sobre-nosotros')}
              >
                SOBRE NOSOTROS
              </a>
           
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contacto">CONTACTO</a>
            </li>
          </ul>
        </div>
      </nav>

     

      {/* Banner principal con imágenes ocultas que aparecen al pasar el cursor */}
      <section id="banner">
        <div className="banner-container">
          <div className="banner-left">
            <img src="\images\banner-main-1.png" alt="Imagen 1" className="img-fluid banner-img" />
            <img src="\images\banner-1-soloizq.png" alt="Imagen Oculta Izquierda" className="img-fluid banner-hidden hidden" />
          </div>
          <div className="banner-right">
            <img src="\images\banner-main-2.png" alt="Imagen 2" className="img-fluid banner-img" />
           
          </div>
          
    </div>
      </section>

             {/* Sección de contacto */}
      <section className="contact-section">
        <h2>¡CONTÁCTANOS AHORA!</h2>
        <p>Estamos aquí para ayudarte. Elige el método de contacto que prefieras:</p>
        <div className="contact-buttons">
          {/* Botón de WhatsApp con ícono */}
          <a href="https://wa.me/56969189172" target="_blank" rel="noopener noreferrer">
            <button className="btn btn-success">
              <i className="fab fa-whatsapp"></i> WhatsApp
            </button>
          </a>

          {/* Botón de Correo Electrónico con ícono */}
          <a href="contacto@gerpa.cl">
            <button className="btn btn-warning">
              <i className="fas fa-envelope"></i> Correo Electrónico
            </button>
          </a>

          {/* Botón de Llamada con ícono */}
          <a href="tel:+123456789">
            <button className="btn btn-danger">
              <i className="fas fa-phone"></i> Llámanos
            </button>
          </a>
        </div>
      </section>

   
    <div>
      {/* Sección Sobre Nosotros */}
      <section id="sobre-nosotros" className="about-section">
        <h2>SOBRE NOSOTROS</h2>
        <p>En GERPA nos especializamos en brindar soluciones de construcción, remodelación y mantenimiento con un enfoque en la calidad, la eficiencia y el compromiso con nuestros clientes.</p>
        <div className="about-links">
          {/* Cambiar <a> por <button> para controlar el contenido dinámico */}
          <button className="about-link" onClick={() => setVisibleSection('servicios')}>NUESTROS SERVICIOS</button>
          <button className="about-link" onClick={() => setVisibleSection('trabajos')}>NUESTROS TRABAJOS</button>
          <button className="about-link" onClick={() => setVisibleSection('conocenos')}>CONÓCENOS MÁS</button>
        </div>
      </section>

      {/* Contenido dinámico que aparece según el botón presionado */}
      {visibleSection === 'servicios' && (
        <section id="servicios" className="dynamic-section">
          <div id="servicios-header"> {/* Div exclusivo para el título y el párrafo */}
      <h2>NUESTROS SERVICIOS</h2>
      <p>GERPA cuenta con la experiencia y la estructura para brindar todo tipo de soluciones en cuanto 
        a planificación y ejecución de obras se refiere, así como el mantenimiento, reparación o remodelación
        de su planta física en todo el territorio nacional.</p>
          </div>


             <ul className="services-list">
    <li>
      <strong>REMODELACIONES, HABILITACIONES, AMPLIACIONES Y CONSTRUCCIONES MENORES</strong>
      <p>Ofrecemos servicios integrales de remodelaciones, habilitaciones, ampliaciones y construcciones menores, adaptados a las necesidades de cada cliente.</p>
    </li>
    <li>
      <strong>PLANIMETRÍA DE CÁLCULO Y ARQUITECTURA</strong>
      <p>Ofrecemos servicios de planimetría de cálculo y arquitectura, fusionando precisión técnica y creatividad para desarrollar proyectos arquitectónicos innovadores y funcionales.</p>
    </li>
    <li>
      <strong>ASESORÍA EN REALIZACIÓN DE PROYECTOS</strong>
      <p>Proporcionamos orientación experta para la ejecución de proyectos, preocupándonos de cada fase del proceso.</p>
    </li>
    <li>
      <strong>ASESORÍA DE RECEPCIÓN DE VIVIENDAS NUEVAS</strong>
      <p>Nuestro enfoque incluye la revisión exhaustiva de todos los aspectos técnicos y de calidad de la vivienda, así como la asistencia en la documentación y trámites necesarios para asegurar la conformidad con los estándares y regulaciones pertinentes.</p>
    </li>
    <li>
      <strong>REPARACIONES E INSTALACIÓN DE CUBIERTAS DE MEMBRANA</strong>
      <p>Ofrecemos servicios especializados en la reparación precisa y la instalación meticulosa de cubiertas de membrana, para asegurar la integridad estructural y la resistencia a largo plazo.</p>
    </li>
    <li>
      <strong>FABRICACIÓN Y MONTAJE DE ESTRUCTURAS METÁLICAS</strong>
      <p>Realizamos montajes de construcción especializados, llevando a cabo instalaciones precisas y eficientes, asegurando la integridad estructural y la seguridad a largo plazo de su proyecto.</p>
    </li>
    <li>
      <strong>OBRAS CIVILES FINAS</strong>
      <p>Nos dedicamos a la ejecución de obras civiles finas, donde cada detalle se cuida meticulosamente para lograr resultados de alta calidad y precisión, garantizando la excelencia en cada proyecto.</p>
    </li>
    <li>
      <strong>TRABAJOS DE ESTUCOS Y HORMIGONADOS</strong>
      <p>Ofrecemos servicios integrales de instalación y mantención eléctrica, fontanera y gasfitería para satisfacer las necesidades de tu hogar o negocio.</p>
    </li>
  </ul>

</section>
)}

      

{visibleSection === 'trabajos' && (
  <section id="trabajos" className="dynamic-section">
    <div id="servicios-header"> {/* Reutilizando el estilo del encabezado de "SERVICIOS" */}
    <h2>NUESTROS TRABAJOS</h2>
    <p>A continuación, te mostramos algunos de nuestros proyectos destacados.</p>
  </div>
    <div className="gallery">
      <div className="gallery-item">
        <img src="\images\cubiertas-1.png" alt="Cubiertas" className="gallery-img"/>
        <img src="\images\metal-1.png" alt="Trabajo 2" className="gallery-img"/>
        
      </div>
      <div className="gallery-item">
        <img src="\images\cubiertas-2.png" alt="Trabajo 2" className="gallery-img"/>
        <img src="\images\metal-2.png" alt="Trabajo 3" className="gallery-img"/>
        
      </div>
      <div className="gallery-item">
        <img src="\images\montaje-1.png" alt="Trabajo 3" className="gallery-img"/>
        </div>
      <div className="gallery-item">
                <img src="\images\montaje-2.png" alt="Trabajo 3" className="gallery-img"/>
      </div>
      {/* Puedes agregar más imágenes con subtítulos aquí */}
    </div>
  </section>
)}


      {visibleSection === 'conocenos' && (
         <section id="conocenos" className="dynamic-section">
         <div id="servicios-header"> 
         <h2>CONÓCENOS MÁS</h2>
         <p>Encuéntranos en nuestras redes sociales:</p>
         </div>
         <div className="social-links">
           {/* Enlace a Instagram */}
           <a href="https://www.instagram.com/tu_cuenta" target="_blank" rel="noopener noreferrer">
             <img src="/images/instagram.png" alt="Instagram" className="social-icon"/>
           </a>
           
           {/* Enlace a TikTok */}
           <a href="https://www.tiktok.com/@tu_cuenta" target="_blank" rel="noopener noreferrer">
             <img src="/images/tiktok.png" alt="TikTok" className="social-icon"/>
           </a>
           
           {/* Enlace a LinkedIn */}
           <a href="https://www.linkedin.com/in/tu_cuenta" target="_blank" rel="noopener noreferrer">
             <img src="/images/linkedin.png" alt="LinkedIn" className="social-icon"/>
           </a>
         </div>
       </section>
      )}
    </div>
 
{/* Footer tradicional */}
<footer className="footer">
        <div className="footer-container">
          <p>&copy; 2024 GERPA. Todos los derechos reservados.</p>
          <ul className="footer-links">
            <li><a href="#privacy">Política de Privacidad</a></li>
            <li><a href="#terms">Términos y Condiciones</a></li>
            <li><a href="#contact">Contacto</a></li>
          </ul>
        </div>
      </footer>
    




      
    </div>
  );
}

export default App;
